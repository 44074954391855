import { graphql, useStaticQuery } from "gatsby"
import { checkForValue } from "./checkValue"

const useCta = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiCta {
        preheader
        heading
        linkTitle
        url
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const { preheader, heading, linkTitle, url } = data.strapiCta

  let ctaTitle = checkForValue(heading)
  let ctaText = checkForValue(preheader)
  let ctaLinkText = checkForValue(linkTitle)
  let ctaUrl = checkForValue(url)
  let ctaImage = data.strapiCta.image.childImageSharp.fluid

  return {
    title: ctaTitle,
    preheader: ctaText,
    image: ctaImage,
    url: ctaUrl,
    linkTitle: ctaLinkText,
  }
}

export default useCta
