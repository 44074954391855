import React from "react"

import SectionElement from "../LayoutComponents/SectionElement"
import Container from "../LayoutComponents/Container"
import ReadLink from "../LayoutComponents/ReadLink"
import TextBox from "../LayoutComponents/TextBox"

import "./Banner.scss"

import useBanner from "../../hooks/useBanner"

const Banner = () => {
  const bannerData = useBanner()

  return (
    <SectionElement className="banner-section">
      <Container>
        <div className="banner-section-content">
          <div className="image-wrapper">
            <h3>{bannerData.title}</h3>
            <img src={bannerData.image} alt={bannerData.text} />
          </div>
          <TextBox className="banner-text">
            <h4>{bannerData.text}</h4>
            <ReadLink to={bannerData.url} target="_blank">
              {bannerData.linkTitle}
            </ReadLink>
          </TextBox>
        </div>
      </Container>
    </SectionElement>
  )
}

export default Banner
