import styled from "@emotion/styled"

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;

  @media screen and (min-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
  }
`

export default TextBox
