import { graphql, useStaticQuery } from "gatsby"

const useProjectFeatures = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiProjectFeatures {
        nodes {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          imageOptional {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
    }
  `)

  return data.allStrapiProjectFeatures.nodes.map(projectFeature => {
    const optionalImage = projectFeature.imageOptional
    let imageSecond = !optionalImage
      ? ""
      : optionalImage.childImageSharp.gatsbyImageData

    return {
      title: projectFeature.title,
      description: projectFeature.description,
      image: projectFeature.image.childImageSharp.gatsbyImageData,
      imageOptional: imageSecond,
    }
  })
}

export default useProjectFeatures
