import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { css, jsx } from "@emotion/react"

import Container from "../LayoutComponents/Container"
import SectionElement from "../LayoutComponents/SectionElement"
import AOS from "aos"
import useHero from "../../hooks/useHero"

import "./Hero.scss"

const TextBox = styled("div")`
  display: flex;
  flex-direction: column;
  max-width: 651px;

  h1 {
    font-size: 48px;
    letter-spacing: -2px;
  }

  p {
    font-size: 24px;
    line-height: 29px;
  }

  @media screen and (min-width: 1024px) {
    margin-left: auto;
    margin-right: auto;

    h1 {
      font-size: 72px;
    }

    p {
      font-size: 26px;
      line-height: 36px;
    }
  }
`

const Hero = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
    })
  }, [])

  const heroData = useHero()

  return (
    <SectionElement id="top">
      <Container>
        <TextBox data-aos="fade-up" data-aos-easing="linear">
          <h1>{heroData.title}</h1>
          <p>{heroData.text}</p>
        </TextBox>
        <div className="hero-section-image">
          <GatsbyImage
            image={heroData.image}
            alt="Singerly - hosting platform for Meltano and Airflow"
            loading="eager"
            css={css`
              img {
                object-fit: inherit !important;
              }
            `}
          />
        </div>
      </Container>
    </SectionElement>
  )
}

export default Hero
