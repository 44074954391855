import { graphql, useStaticQuery } from "gatsby"
import { checkForValue } from "./checkValue"

const useBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiBanner {
        heading
        text
        linkTitle
        url
        image {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  `)

  const { heading, text, linkTitle, url } = data.strapiBanner

  let bannerTitle = checkForValue(heading)
  let bannerText = checkForValue(text)
  let bannerLinkText = checkForValue(linkTitle)
  let bannerUrl = checkForValue(url)
  let bannerImage = data.strapiBanner.image.childImageSharp.fluid.src

  return {
    title: bannerTitle,
    text: bannerText,
    image: bannerImage,
    url: bannerUrl,
    linkTitle: bannerLinkText,
  }
}

export default useBanner
