import React, {useEffect} from "react";
import AOS from "aos";

import SectionElement from "../LayoutComponents/SectionElement";
import Container from "../LayoutComponents/Container";

import useProjectFeatures from "../../hooks/useProjectFeatures";

import "./ProjectFeature.scss";
import ProjectImage from "./ProjectImage";

const ProjectFeature = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  const projectFeatures = useProjectFeatures();

  return (
    <>
      {projectFeatures.map((feature, key) => {
        return (
          <SectionElement className="project-features" key={key}>
            <Container>
              <div className="feature-card" key={feature.title}>
                <div
                  className="col"
                  data-aos="fade-up"
                  data-aos-easing="linear"
                >
                  <h2>{feature.title}</h2>
                  <p>{feature.description}</p>
                </div>
                <div className="col" data-aos="fade-right">
                  <ProjectImage feature={feature} />
                </div>
              </div>
            </Container>
          </SectionElement>
        );
      })}
    </>
  );
};
export default ProjectFeature;
