import styled from "@emotion/styled"

import { Link } from "gatsby"

const ReadLink = styled(Link)`
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  text-decoration: none;
  background-color: #3438BF;
  border-radius: 30px;
  padding: 12px 24px;
  color: #fff;
  transition: all 0.5s;
  width: max-content;

  &:hover {
    background-color: #2998ff !important;
    box-shadow: 0 8px 16px rgb(41 152 255 / 24%);
  }
`

export default ReadLink
