import React, { useEffect } from "react"
import styled from "@emotion/styled"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import AOS from "aos"

import ReadLink from "../LayoutComponents/ReadLink"
import SectionElement from "../LayoutComponents/SectionElement"
import Container from "../LayoutComponents/Container"
import useCta from "../../hooks/useCta"

const ImageBackground = styled(BackgroundImage)`
  background-size: cover;
  background-position: center;
  background-color: #000;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  + * {
    margin-top: 0;
  }
`

const TextBox = styled("div")`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  z-index: 5;

  h6 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-family: "Roboto", sans-serif;
  }

  h2 {
    font-family: "Roboto", Sans-serif;
    font-size: 56px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: -1.5px;
  }

  .get-started {
    background-color: #00d0bb;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    font-family: "Roboto", Sans-serif;
  }
`

const CTA = () => {
  const ctaData = useCta()

  useEffect(() => {
    AOS.init({
      duration: 700,
    })
  }, [])

  return (
    <SectionElement>
      <ImageBackground
        Tag="div"
        fluid={ctaData.image}
        fadeIn="soft"
        className="cta-back"
      >
        <div className="background-shading"></div>
        <Container className="cta-content">
          <TextBox data-aos="fade-up" data-aos-easing="linear">
            <h6>{ctaData.preheader}</h6>
            <h2>{ctaData.title}</h2>
            <ReadLink to={ctaData.url} className="get-started">
              {ctaData.linkTitle}
            </ReadLink>
          </TextBox>
        </Container>
      </ImageBackground>
    </SectionElement>
  )
}

export default CTA
