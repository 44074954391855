import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import ProjectFeature from "../components/ProjectFeature/ProjectFeature";
import Hero from "../components/Hero/Hero";
import Banner from "../components/Banner/Banner";
import Cta from "../components/CTA/CTA";

import "aos/dist/aos.css";

const IndexPage = () => {
  return (
    <>
      <Layout>
        <Seo title="Singerly" />
        <Hero />
        <Banner />
        <ProjectFeature />
        <Cta />
      </Layout>
    </>
  );
};

export default IndexPage;
