import { graphql, useStaticQuery } from "gatsby"

import { checkForValue } from "./checkValue"

const useHero = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHero {
        heading
        text
        image {
          childImageSharp {
            gatsbyImageData(
              height: 646
              placeholder: BLURRED
              formats: WEBP
              quality: 100
            )
          }
        }
      }
    }
  `)

  const { heading, text } = data.strapiHero

  let heroTitle = checkForValue(heading)
  let heroText = checkForValue(text)
  let heroImage = data.strapiHero.image.childImageSharp.gatsbyImageData

  return {
    title: heroTitle,
    text: heroText,
    image: heroImage,
  }
}

export default useHero
