import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

const ProjectImage = ({feature}) => {
  if (!feature.imageOptional) {
    return (
      <>
        <GatsbyImage image={feature.image} alt={feature.title} />
      </>
    );
  } else {
    return (
      <>
        <GatsbyImage
          image={feature.image}
          alt={feature.title}
          className="image-main"
        />

        <GatsbyImage
          image={feature.imageOptional}
          alt={feature.title}
          className="image-optional"
        />
      </>
    );
  }
};

export default ProjectImage;
